import SwaggerUI from 'swagger-ui';
import { AureliaConfiguration } from 'aurelia-configuration';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class ApiDocs {
    private docsElement: HTMLElement;
    private readonly apiUrl: string;
    private readonly apiDescription: string;

    constructor(private config: AureliaConfiguration) {
        this.apiUrl = config.get('credentials.cyberApiEndpoint');
        this.apiDescription = config.get('credentials.cyberApiDescription');
    }

    private async attached(): Promise<void> {
        // Retrieve the Swagger Spec as we need to add servers to it and fix $ref URLs. Instead of importing it directly
        // as a JSON file, it is fetched here to prevent duplicate path declaration
        const specUrl = new URL('/assets/documents/cyberguard-api-swagger-22f5a476-8442-4c97-8ffa-0bc0339520ea.json', window.location.href);
        const fetchSpecResponse = await fetch(specUrl);
        const swaggerSpec = await fetchSpecResponse.json();

        // Add the CyberGuard API as the API server to the Swagger Spec to support the "Try it out" functionality, this
        // would otherwise use the current page's URL as the API server, which is wrong
        swaggerSpec.servers = [
            { url: this.apiUrl, description: this.apiDescription },
        ];

        // Make a snapshot of the Swagger Spec with original $ref URLs for download. For example, Postman would not
        // support the modified $ref URLs, so the original spec needs to be made available for download.
        const swaggerSpecStringWithOriginalRefs = JSON.stringify(swaggerSpec, null, 2);

        // Fix $ref URLs to be absolute URLs, as Swagger UI doesn't support relative URLs combined with a blob
        function fixSwaggerSpecReferences(obj: any): void {
            if (typeof obj !== 'object' || obj === null)
                return;

            for (const [key, value] of Object.entries(obj)) {
                if (key === '$ref' && typeof value === 'string' && !value.startsWith('http'))
                    obj[key] = new URL(value, specUrl).href; // Convert to absolute URL
                else
                    fixSwaggerSpecReferences(value);
            }
        }

        fixSwaggerSpecReferences(swaggerSpec);

        // Create a Swagger Spec file and URL so Swagger UI allows the user to download the spec while allowing us to
        // modify the spec with API server URLs
        const swaggerSpecFile = new File([JSON.stringify(swaggerSpec, null, 2)], 'cyberguard-api-swagger.json', { type: 'octet/stream' });
        const swaggerSpecUrl = URL.createObjectURL(swaggerSpecFile);

        // Convert the original (unmodified) spec to a Blob URL for downloading
        const originalBlob = new Blob([swaggerSpecStringWithOriginalRefs], { type: 'application/json' });
        const originalBlobUrl = URL.createObjectURL(originalBlob);

        // Requires polyfill for 'path' using `path-browserify` in Webpack config
        SwaggerUI({
            domNode: this.docsElement,
            url: swaggerSpecUrl,
            persistAuthorization: true,
            filter: true,
            tryItOutEnabled: true,
            // After Swagger UI has loaded, modify the download link to point to the original (unmodified) spec, some
            // consumers of the spec (e.g. Postman) do not support the modified $ref URLs
            onComplete: () => {
                const swaggerHeader = this.docsElement.querySelector('.information-container');
                if (swaggerHeader === null)
                    throw new Error('Expected Swagger UI header (.information-container) to be present');

                const swaggerSpecDownloadLink: HTMLAnchorElement = swaggerHeader.querySelector('.link');
                if (swaggerSpecDownloadLink === null)
                    throw new Error('Expected Swagger UI header link (.link) to be present');
                swaggerSpecDownloadLink.href = originalBlobUrl;
                swaggerSpecDownloadLink.download = 'cyberguard-api-swagger.json';

                const swaggerSpecDownloadLinkUrl: HTMLSpanElement = swaggerSpecDownloadLink.querySelector('.url');
                if (swaggerSpecDownloadLinkUrl === null)
                    throw new Error('Expected Swagger UI header link URL (.url) to be present');
                swaggerSpecDownloadLinkUrl.innerHTML = 'Download cyberguard-api-swagger.json';
            }
        });
    }
}
